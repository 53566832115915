import React from "react";
import { useState, useEffect } from "react";
import CryptoJS from "crypto-js";

export function encrypt(plainText, key) {
  // Convert the key to a WordArray from its MD5 hash
  key = CryptoJS.enc.Hex.parse(CryptoJS.MD5(key).toString(CryptoJS.enc.Hex));

  // Initialize the initialization vector
  const initVector = CryptoJS.enc.Hex.parse(
    '000102030405060708090a0b0c0d0e0f'
  );

  // Encrypt the plain text with AES-128-CBC
  const encrypted = CryptoJS.AES.encrypt(plainText, key, {
    iv: initVector,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  return encrypted.toString();
}

// Example usage:
// const plainText = 'Hello, World!';
// const key = 'your_secret_key';

