import logo from "./logo.svg";
import "./App.css";
import { encrypt } from "./components/Encryption";
import { useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import axios from "axios";

function App() {
  const {
    register,
    handleSubmit,
    watch,
    formState,
  } = useForm();

  const onSubmit = (data) => {
    console.log("formData: ", data)
    data.merchant_id = "2649128";
    data.language = "EN";
    data.amount = "1";
    data.currency = "INR";
    data.redirect_url = "https://roadserve.in/payment-check";
    data.cancel_url = "https://roadserve.in/payment/success=false";

    console.log("Updated data: ", data)

    const enc = encrypt(JSON.stringify(data), "3FDD37C352F395B09AAE1745DAFD3123")
    console.log("Encrypted data: ", enc)
    const req = new FormData()
    req.append("encRequest", enc)
    req.append("access_code", "AVZW87KG30AW18WZWA")
    axios.post("https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction", req)
    .then((res) => console.log("response: ", res))
    .catch((err) => {
      console.log("erorr is : ", err)
    })
  } 

  useEffect(() => {});
  return (
    <div className="App">
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          name="billing_name"
          placeholder="Name"
          {...register("billing_name")}
        />
        <input name="billing_address" placeholder="Address" {...register("billing_address")} />
        <input name="billing_state" placeholder="State" {...register("billing_state")} />
        <input name="billing_zip" placeholder="Pin Code" {...register("billing_zip")} />
        <input name="billing_country" placeholder="Country" defaultValue="India" {...register("billing_country")} />
        <input name="billing_tel" placeholder="Mobile Number" {...register("billing_tel")} />
        <input name="billing_email" placeholder="Email ID" {...register("billilng_email")} />
        <input type="submit" />
      </form>
    </div>
  );
}

export default App;
